import { create } from "zustand";
import { persist } from "zustand/middleware";

import appendVariantUniquely from "@microsite/lib/cart/appendVariantUniquely";
import getPathname from "@utils/routes/getPathname";
import storageLocal from "@utils/state/storageLocal";

import type { CartSettings, CartVariant } from "@microsite/state/cart/types";

const BASE_KEY = "SF-CART-STATE";
const VERSION = 0;
const EXPIRATION_IN_DAYS = 7;

interface CartState {
  /* Cart variants */
  variants: CartVariant[];
  /* Cart discount code */
  discountCode: string;
  /* Cart app settings */
  settings: CartSettings | null | undefined;

  /* Whether the cart app is enabled */
  isEnabled: boolean;
  /* Whether the cart is open */
  isOpen: boolean;
  /* Whether the cart is redirecting to checkout */
  isRedirecting: boolean;
}

interface CartActions {
  appendVariant: (input: CartVariant) => void;
  setVariants: (input: CartVariant[]) => void;

  setDiscountCode: (input: string) => void;
  enable: (input: CartSettings) => void;
  reset: () => void;

  open: () => void;
  close: () => void;

  setRedirecting: () => void;
  clearRedirecting: () => void;
}

const useCartState = create<CartState & CartActions>()(
  persist(
    (set) => ({
      discountCode: "",
      variants: [],
      settings: undefined,

      isEnabled: false,
      isOpen: false,
      isRedirecting: false,

      appendVariant: (input) =>
        set((state) => ({
          variants: appendVariantUniquely(state.variants, input),
        })),
      setVariants: (input) => set(() => ({ variants: input })),

      setDiscountCode: (input) => set(() => ({ discountCode: input })),

      enable: (input) => set(() => ({ isEnabled: true, settings: input })),
      reset: () =>
        set(() => ({
          discountCode: "",
          variants: [],
          isOpen: false,
          isRedirecting: false,
        })),

      open: () => set(() => ({ isOpen: true })),
      close: () => set(() => ({ isOpen: false })),
      setRedirecting: () => set(() => ({ isRedirecting: true })),
      clearRedirecting: () => set(() => ({ isRedirecting: false })),
    }),
    {
      name: `${BASE_KEY}${getPathname().join("-").toUpperCase()}`,
      version: VERSION,
      storage: storageLocal(EXPIRATION_IN_DAYS),
      partialize: ({ discountCode, variants, isOpen }) => ({
        discountCode,
        variants,
        isOpen,
      }),
    },
  ),
);

export default useCartState;
