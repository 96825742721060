import { create } from "zustand";

import { Shop } from "@superfiliate/graphql-sdk/src/lib/__generated__";

type ShopState = Partial<Shop>;

interface ShopActions {
  /* Set the shop payload */
  setShop: (input: Partial<Shop>) => void;
}

const useShopState = create<ShopState & ShopActions>()((set) => ({
  setShop: (input) => set(() => input),
}));

export default useShopState;
