import { createJSONStorage, StateStorage } from "zustand/middleware";

/**
 * Custom localStorage store middleware for Zustand, responsible for persisting
 * the current state on the browser Local Storage
 *
 * It accepts an optional expiration, in number of days, for its storage
 *
 * Intended to be used solely with Zustant persisting algorithm
 */
const storageLocal = (expirationInDays?: number) =>
  createJSONStorage(
    (): StateStorage => ({
      getItem: (key) => {
        const data = localStorage.getItem(key);

        if (!expirationInDays) return data;

        const state = JSON.parse(data || "{}").state;

        const lastModifiedAt = state?.lastModifiedAt;
        const lastModifiedDelta =
          lastModifiedAt &&
          (new Date().getTime() - new Date(lastModifiedAt).getTime()) /
            (24 * 60 * 60 * 1000);

        if (lastModifiedDelta && lastModifiedDelta > expirationInDays) {
          localStorage.removeItem(key);

          return null;
        }

        return data;
      },
      setItem: (key, value) => {
        const data = JSON.parse(value || "{}");

        data.state["lastModifiedAt"] = new Date();

        localStorage.setItem(key, JSON.stringify(data));
      },
      removeItem: (key) => {
        localStorage.removeItem(key);
      },
    }),
  );

export default storageLocal;
