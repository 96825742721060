import clsx from "clsx";

/**
 * We use the `clsx` package to handle classnames in our project
 *
 * This module is a simple re-export of the `clsx` package
 * so that we can easily switch to another package in the future, or
 * introduce custom logic to handle classnames
 *
 * @see https://www.npmjs.com/package/clsx
 */
export default clsx;
