import Link from "next/link";
import React from "react";

import LoadingSpinner from "@customer-ui/components/LoadingSpinner";
import cn from "@utils/cn";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?:
    | "primary"
    | "primary-content"
    | "base"
    | "base-content"
    | "white"
    | "black"
    | "danger"
    | "danger-content"
    | "success"
    | "success-content";
  kind?: "normal" | "outline" | "outline-bg" | "ghost";
  shadow?: boolean;
  href?: string;
  newTab?: boolean;
  loading?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => unknown;
  "data-testid"?: string;
}

const COLOR_VARIABLES: Record<string, string[]> = {
  base: ["var(--sf-base-swappable)", "var(--sf-base-content-swappable)"],
  primary: [
    "var(--sf-primary-swappable)",
    "var(--sf-primary-content-swappable)",
  ],
  success: ["var(--sf-success-color)", "var(--sf-success-content-color)"],
  danger: ["var(--sf-danger-color)", "var(--sf-danger-content-color)"],
  white: ["255,255,255", "0,0,0"],
  black: ["0,0,0", "255,255,255"],
};

const NEW_TAB_PROPS = {
  rel: "noopener noreferrer",
  target: "_blank",
};

const Button: React.FC<ButtonProps> = ({
  className = "",
  children,
  color = "base-content",
  kind = "normal",
  type = "button",
  shadow = false,
  loading = false,
  href,
  newTab,
  style,
  disabled = false,
  onClick,
  ...rest
}: ButtonProps) => {
  let colorVariables =
    COLOR_VARIABLES[color.split("-")[0]] || COLOR_VARIABLES["base"];

  const contentColor = color.indexOf("-content") >= 0;
  const normal = kind === "normal";
  const outlineWithoutBg = kind === "outline";
  const outlineWithBg = kind === "outline-bg";
  const ghost = kind === "ghost";

  const outline = outlineWithoutBg || outlineWithBg;
  // XOR, inverts the colors if it is only "content" color, or only "outline" kind.
  if ((!contentColor && outline) || (contentColor && !outline)) {
    colorVariables = colorVariables.slice().reverse();
  }

  const mxAuto = className.includes("mx-auto");
  const commonProps = {
    className: cn(
      "z-10",
      "font-body font-bold tracking-normal text-center",
      "transition-all duration-300 ease-in-out",
      "rounded-sf-button-radius disabled:opacity-75",
      "focus:outline-4 outline-primary/80 outline-offset-4",
      "disabled:opacity-50 disabled:pointer-events-none",
      {
        "inline-block": !mxAuto,
        block: mxAuto,
        "px-[2.5em] py-[0.75em]": !ghost,
        "text-accent hover:underline": ghost,
        "text-accent-content hover:text-accent active:text-accent-content":
          !ghost,
        "bg-accent": !ghost && !outlineWithoutBg,
        "hover:bg-accent-content active:bg-accent": !ghost,
        "border border-accent": normal,
        "border border-accent-content": outlineWithoutBg || outlineWithBg,
      },
      className,
    ),
    style: {
      "--sf-accent-swappable": colorVariables[0],
      "--sf-accent-content-swappable": colorVariables[1],
      ...style,
    },
    disabled: disabled || loading,
    onClick,
  };

  if (shadow) {
    commonProps.style.boxShadow = "0px 2px 0px 0px #0000003D";
  }

  const content = loading ? (
    <>
      <LoadingSpinner />
      <span className={cn("pl-2")}>Loading...</span>
    </>
  ) : (
    children
  );

  const testID = rest?.["data-testid"] as string;

  if (href) {
    const newTabProps = newTab ? NEW_TAB_PROPS : {};

    if (commonProps.disabled) {
      commonProps.style.pointerEvents = "none";
      commonProps.style.opacity = "50%";
    }

    return (
      <Link data-testid={testID} href={href} {...commonProps} {...newTabProps}>
        {content}
      </Link>
    );
  }

  return (
    <button data-testid={testID} type={type} {...commonProps} {...rest}>
      <span>{content}</span>
    </button>
  );
};

export default Button;
