import areVariantsEqual from "@microsite/lib/cart/areVariantsEqual";

import type { CartVariant } from "@microsite/state/cart/types";

/**
 * Given a list of existing cart variants, appends the new one on the list, returning a
 * grouped version of the list, based on the same variants
 *
 * e.g.
 *
 * list - [{ id: 1, quantity: 2 }, { id: 2, quantity: 5 }]
 * variant - { id: 1, quantity: 2 }
 *
 * returns - [{ id: 1, quantity: 4 }, { id: 2, quantity: 5 }]
 *
 * e.g.
 *
 * list - [{ id: 1, quantity: 2 }, { id: 2, quantity: 5 }]
 * variant - { id: 3, quantity: 2 }
 *
 * returns - [{ id: 1, quantity: 2 }, { id: 2, quantity: 5 }, { id: 3, quantity: 2 }]
 *
 */
const appendVariantUniquely = (list: CartVariant[], variant: CartVariant) => {
  let changedExistingEntry = false;

  const uniqueList = list.map((savedVariant) => {
    if (!areVariantsEqual(savedVariant, variant)) return savedVariant;

    changedExistingEntry = true;

    return {
      ...savedVariant,
      quantity: savedVariant.quantity + (variant.quantity || 1),
    };
  });

  if (!changedExistingEntry) {
    uniqueList.push({
      ...variant,
      quantity: variant.quantity || 1,
    });
  }

  return uniqueList;
};

export default appendVariantUniquely;
