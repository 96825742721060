import type { CartVariant } from "@microsite/state/cart/types";

/**
 * Checks whether the two given variants in a cart are equal, based on the variant ID
 * and subscription frequencies
 *
 * @param firstVariant - The first variant to compare
 * @param secondVariant - The second variant to compare
 *
 * @returns boolean
 */
const areVariantsEqual = (
  firstVariant: CartVariant,
  secondVariant: CartVariant,
): boolean => {
  const matchingId = firstVariant.id === secondVariant.id;

  if (!matchingId) return false;

  const subscriptionDetailsEmpty = !(
    firstVariant.sellingPlan ||
    secondVariant.sellingPlan ||
    firstVariant.intervalUnit ||
    secondVariant.intervalUnit ||
    firstVariant.intervalFrequency ||
    secondVariant.intervalFrequency
  );

  if (subscriptionDetailsEmpty) return true;

  const matchingSellingPlan =
    !!firstVariant.sellingPlan &&
    !!secondVariant.sellingPlan &&
    firstVariant.sellingPlan === secondVariant.sellingPlan;

  const matchingIntervalUnit =
    !!firstVariant.intervalUnit &&
    !!secondVariant.intervalUnit &&
    firstVariant.intervalUnit === secondVariant.intervalUnit;

  const matchingIntervalFrequency =
    !!firstVariant.intervalFrequency &&
    !!secondVariant.intervalFrequency &&
    firstVariant.intervalFrequency === secondVariant.intervalFrequency;

  const matchingSubscription =
    matchingSellingPlan || (matchingIntervalUnit && matchingIntervalFrequency);

  return matchingSubscription;
};

export default areVariantsEqual;
