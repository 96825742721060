import React, { FC } from "react";
import { twMerge } from "tailwind-merge";

import Skeleton from "@customer-ui/components/CardSkeleton";
import cn from "@utils/cn";
import useBreakpointOrGreater from "@utils/hooks/useBreakpointOrGreater";
import useBreakpointOrLower from "@utils/hooks/useBreakpointOrLower";

export interface CardProps {
  className?: string;
  withShadow?: boolean;
  unstyledOnBreakpointOrLower?: string;
  unstyledOnBreakpointOrGreater?: string;
  onClick?: () => void;
  skeleton?: boolean;
}

const Card: FC<React.PropsWithChildren<CardProps>> = ({
  className,
  children,
  withShadow = false,
  unstyledOnBreakpointOrLower,
  unstyledOnBreakpointOrGreater,
  onClick,
  skeleton = false,
}) => {
  const unstyledLower = useBreakpointOrLower(
    unstyledOnBreakpointOrLower as string,
  );
  const unstyledGreater = useBreakpointOrGreater(
    unstyledOnBreakpointOrGreater as string,
  );

  if (skeleton) {
    return <Skeleton className={className} />;
  }

  if (unstyledLower || unstyledGreater) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div
      className={twMerge(
        cn(
          "rounded-sf-radius bg-base p-6 text-center text-base-content",
          !withShadow && "border border-base-content/10",
          className,
        ),
      )}
      style={{
        boxShadow: withShadow ? "0px 4px 4px rgba(0, 0, 0, 0.06)" : "",
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
