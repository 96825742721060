import useMediaQuery, { BREAKPOINTS } from "@utils/hooks/useMediaQuery";

const useBreakpointOrGreater: (
  breakpoint: string,
  bypassEmbedded?: boolean
) => boolean = (breakpoint, bypassEmbedded = false) =>
  useMediaQuery(
    `(min-width: ${
      BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS]?.lower || 9999999
    }px)`,
    false,
    bypassEmbedded
  );

export default useBreakpointOrGreater;
