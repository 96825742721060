import { FC } from "react";

import cn from "@utils/cn";

interface CardSkeletonProps {
  className?: string;
}

const CardSkeleton: FC<CardSkeletonProps> = ({ className }) => (
  <div
    className={cn(
      "rounded-2xl bg-white p-6 text-center border border-gray-300",
      className,
    )}
  >
    <div className="animate-pulse px-6 pb-10 md:px-2 md:pb-0">
      <div className="flex flex-col items-center gap-2">
        <p className="h-6 w-5/12 bg-gray-300 mb-2" />

        <p className="h-6 w-full bg-gray-300" />
        <p className="h-6 w-11/12 bg-gray-300" />

        <div className="flex flex-col w-full mt-2 gap-2">
          <p className="h-24 bg-gray-300 rounded-xl" />
          <p className="h-24 bg-gray-300 rounded-xl" />
        </div>
      </div>
    </div>
  </div>
);

export default CardSkeleton;
