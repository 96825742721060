"use client";

/* eslint-disable react-hooks/rules-of-hooks, @typescript-eslint/no-explicit-any */

import { useEffect, useState } from "react";
import { useMedia } from "react-use";

import isClient from "@utils/isClient";

/**
 * The semantic breakpoints used in the app. More details can be found in the
 * design system configuration file
 *
 * - packages/config/tailwind/base-ui/screens.ts
 */
export const BREAKPOINTS = {
  xs: { lower: -1, upper: 640 },
  md: { lower: 768, upper: 1024 },
  tablet: { lower: 481, upper: 1024 },
  laptop: { lower: 1025, upper: 1440 },
  desktop: { lower: 1441, upper: 1920 },
  "large-desktop": { lower: 1921, upper: 9999999 },
};

/**
 * Custom hook to use media queries against the $embedded.contentWindow object
 * when the app is embedded inside an iframe
 *
 * @note - This hooks is not intended to be used directly. Use `useMediaQuery` instead.
 */
const useEmbeddedMedia = (query: string, defaultIfUnsupported: boolean) => {
  if (!isClient || !(window as any).$embedded?.contentWindow)
    return defaultIfUnsupported;

  const [matches, setMatches] = useState(
    (window as any).$embedded?.contentWindow.matchMedia(query)?.matches
  );

  useEffect(() => {
    const media = ((window as any).$embedded?.contentWindow).matchMedia(query);
    if (!media) return;

    const listener = () => {
      setMatches(media.matches);
    };

    try {
      media.addEventListener("change", listener);
    } catch (e) {
      /* Safari does not support `media.addEventListener` */
      media.addListener(listener);
    }

    return () => {
      try {
        media.removeEventListener("change", listener);
      } catch (e) {
        /* Safari does not support `media.removeEventListener` */
        media.removeListener(listener);
      }
    };
  }, [query]);

  return matches;
};

/**
 * Custom hook to use media queries in the app
 *
 * @param query - The media query to be used
 * @param defaultIfUnsupported - The default value to be returned if the media
 *
 * @returns - The result of the media query
 */
const useMediaQuery = (
  query: string,
  defaultIfUnsupported = false,
  bypassEmbedded = false
): boolean => {
  /* Embedded inside iframe */
  if (isClient && !bypassEmbedded && (window as any).$embedded?.contentWindow)
    return useEmbeddedMedia(query, defaultIfUnsupported);

  /* Default behavior */
  return useMedia(query, defaultIfUnsupported);
};

export default useMediaQuery;
