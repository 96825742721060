import NextImage, { ImageProps as NextImageProps } from "next/image";
import React, { useEffect, useState } from "react";

import extractMediaID from "@utils/media/extractMediaID";
import generateImageURL from "@utils/media/generateImageURL";

const cloudflareLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  const params = [`width=${width}`];

  if (quality) {
    params.push(`quality=${quality}`);
  }

  const blurred = src.endsWith("/blur");

  if (blurred) {
    src = src.replace("/blur", "");
    params.push("blur=75");
  }

  const paramsString = `${params.join(",")}`;

  const mediaId = extractMediaID(src);

  return generateImageURL(mediaId || src, paramsString);
};

export const ANIMATED_PLACEHOLDER =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwMCIgaGVpZ2h0PSIxMDAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogIDxyZWN0IHdpZHRoPSIxMDAwIiBoZWlnaHQ9IjEwMDAiIGZpbGw9IiNjMmMyYzIiIG9wYWNpdHk9IjAuMjUiIC8+CiAgPHJlY3QgaWQ9InIiIHdpZHRoPSIxMDAwIiBoZWlnaHQ9IjEwMDAiIGZpbGw9IiNjMmMyYzIiIC8+CiAgPGFuaW1hdGUgeGxpbms6aHJlZj0iI3IiIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiIHZhbHVlcz0iMC44OzA7MC44IiBkdXI9IjJzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgIC8+Cjwvc3ZnPgo=";

export interface ImageProps
  extends Omit<
    NextImageProps,
    | "layout"
    | "objectFit"
    | "objectPosition"
    | "lazyBoundary"
    | "lazyRoot"
    | "blurDataURL"
    | "placeholder"
  > {
  blur?: boolean;
  placeholder?: boolean;
  fallbackSrc?: string;
}

const Image: React.FC<ImageProps> = ({
  src,
  sizes,
  alt,
  quality,
  fallbackSrc,
  blur = false,
  placeholder = true,
  className,
  ...props
}) => {
  const [error, setError] = useState<React.SyntheticEvent<
    HTMLImageElement,
    Event
  > | null>(null);

  useEffect(() => {
    setError(null);
  }, [src]);

  const imageSrc = !src ? fallbackSrc : blur ? `${src}/blur` : src;

  if (!imageSrc) return null;

  return (
    <NextImage
      alt={alt}
      className={className}
      loader={cloudflareLoader}
      placeholder={placeholder ? ANIMATED_PLACEHOLDER : undefined}
      quality={quality || 100}
      sizes={
        sizes
          ? sizes
          : "(max-width: 1024px) 100vw, (max-width: 1920px) 50vw, 25vw"
      }
      src={error && fallbackSrc ? fallbackSrc : imageSrc}
      onError={setError}
      {...props}
    />
  );
};

export default Image;
