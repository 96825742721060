/**
 * Accepts a media ID connected to an image and returns
 * an URL connected to the image (i.e. the WEBP/AVIF equivalent)
 *
 * @example
 *
 * generateImageURL("awds") => "https://imagedelivery.net/accountID/awds"
 *
 * @param mediaID - The media ID of the image
 * @param params - Optional comma-separated list of parameters (e.g. "w=128,q=80")
 */
const generateImageURL = (mediaID: string, params?: string): string => {
  const isFromShopifyCDN = mediaID.includes("cdn.shopify.com");
  const isExternalURL = mediaID.startsWith("http");
  const parsedParams = params || "quality=85";

  if (isFromShopifyCDN) {
    const [url, shopifyParams] = mediaID.split("?");
    return shopifyParams
      ? `${url}?${parsedParams.replace(",", "&")}&${shopifyParams}`
      : `${url}?${parsedParams.replace(",", "&")}`;
  }

  if (isExternalURL)
    return `https://superfiliate-cdn.com/cdn-cgi/image/${parsedParams}/${mediaID}`;

  return `https://imagedelivery.net/JxMt8_0v0PNFhBJLkwBu2A/${mediaID}/${parsedParams}`;
};

export default generateImageURL;
