import useMediaQuery, { BREAKPOINTS } from "@utils/hooks/useMediaQuery";

const useBreakpointOrLower: (
  breakpoint: string,

  bypassEmbedded?: boolean
) => boolean = (breakpoint, bypassEmbedded = false) =>
  useMediaQuery(
    `(max-width: ${
      BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS]?.upper || -1
    }px)`,
    false,
    bypassEmbedded
  );

export default useBreakpointOrLower;
