/**
 * Accepts an image source from cloudflare and returns the mediaId
 *
 * @example
 *
 * extractMediaID("https://imagedelivery.net/JxMt8_0v0PNFhBJLkwBu2A/a1eb7f5e-660f-4ab4-d5fd-5b778d64a800/public") => "a1eb7f5e-660f-4ab4-d5fd-5b778d64a800"
 *
 * @param src - The image SRC from cloudflare
 */
const extractMediaID = (src: string): string | null => {
  if (!src.includes("https://imagedelivery.net")) {
    return null;
  }

  const mediaIDMatch = src.match(
    /\/([a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12})\//,
  );

  return mediaIDMatch ? mediaIDMatch[1] : null;
};

export default extractMediaID;
